<template>
  <div>
    <b-table-simple responsive>
      <b-tbody>
        <template v-if="teaching_profiles.length > 0">
          <template v-for="(teaching_profile, index) in teaching_profiles">
            <b-tr :key="'profile_header_' + teaching_profile.id">
              <b-th colspan="10" rowspan="1">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="flex-grow-1 text-center">
                    PERFIL DOCENTE {{ index + 1 }}
                  </span>
                  <div>
                    <ButtonSmall
                      v-if="
                        index == 0 &&
                        allows_crud &&
                        oldMatter &&
                        !oldMatter.is_closed
                      "
                      :tooltip_text="`Agregar un nuevo Perfil Docente`"
                      :click_button="createTeachingProfileBase"
                      :class="`ml-1`"
                      :icon="'plus'"
                      :variant="'none'"
                    >
                    </ButtonSmall>
                    <ButtonSmall
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                      :click_button="
                        () => deleteTeachingProfile(teaching_profile)
                      "
                      :tooltip_text="`Eliminar el Perfil Docente`"
                      :class="`ml-1`"
                      :icon="'trash'"
                    ></ButtonSmall>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div
                    style="color: white !important; padding: 0.1em"
                    class="mr-2 secondary-color rounded badge-teaching d-flex"
                  >
                    {{
                      $getVisibleNames(
                        "manual.total_pedagogical_hours",
                        true,
                        "Total de Horas Pedagógicas"
                      )
                    }}
                    <span
                      v-if="!allows_crud || (oldMatter && oldMatter.is_closed)"
                      >:
                      {{
                        teaching_profile.total_hours
                          ? teaching_profile.total_hours
                          : "N/A"
                      }}
                    </span>
                    <b-form-input
                      v-else
                      :id="`input-teaching-profile-${teaching_profile.id}`"
                      type="number"
                      min="0"
                      :state="
                        totalPedagogical -
                          maxHoursTeachingProfile(teaching_profile.id) >=
                        teaching_profile.total_hours
                      "
                      @input="patchHourTeachingProfile(teaching_profile)"
                      v-model.number="teaching_profile.total_hours"
                      class="input-number-hour ml-1"
                    ></b-form-input>
                    <b-popover
                      v-if="
                        allows_crud &&
                        totalPedagogical -
                          maxHoursTeachingProfile(teaching_profile.id) <
                          teaching_profile.total_hours
                      "
                      variant="warning"
                      placement="bottom"
                      :target="`input-teaching-profile-${teaching_profile.id}`"
                      show
                    >
                      <template>
                        La suma de las horas de los docentes superó el máximo:
                        {{ totalPedagogical }}
                      </template>
                    </b-popover>
                  </div>
                  <div
                    style="color: white !important; padding: 0.1em"
                    class="mr-2 secondary-color rounded badge-teaching d-flex"
                    v-b-tooltip.v-secondary.noninteractive.hover.top="
                      `Años de experiencia laboral en el área.`
                    "
                  >
                    Años de experiencia profesional
                    <span
                      v-if="!allows_crud || (oldMatter && oldMatter.is_closed)"
                      >:
                      {{
                        teaching_profile.experience_years
                          ? teaching_profile.experience_years
                          : "N/A"
                      }}
                    </span>
                    <b-form-input
                      v-else
                      type="number"
                      min="0"
                      v-model.number="teaching_profile.experience_years"
                      @input="
                        (value) => {
                          if (value >= 0 && teaching_profile.total_hours >= 0)
                            patchTeachingProfile(teaching_profile);
                          else if (value < 0)
                            teaching_profile.experience_years = 0;
                        }
                      "
                      class="input-number-year ml-1"
                    ></b-form-input>
                  </div>
                </div>
              </b-th>
            </b-tr>
            <b-tr :key="'professional_title_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.titulo_profesional",
                      false,
                      "Título Profesional"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <div
                    class="wrapper2 rich-text-content w-100"
                    v-html="teaching_profile.professional_title"
                  ></div>
                  <template
                    v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  >
                    <div class="d-flex align-content-center p-1">
                      <ButtonSmall
                        tabindex="-1"
                        :tooltip_text="`Editar`"
                        :click_button="
                          () =>
                            $bvModal.show(
                              `edit-professional_title-modal-${teaching_profile.id}-${matter_form_view}`
                            )
                        "
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>
                      <ButtonSmall
                        v-if="teaching_profile.professional_title"
                        tabindex="-1"
                        :tooltip_text="`Limpiar`"
                        :click_button="
                          () => clearProfessionalTitle(teaching_profile)
                        "
                        :class="`ml-1`"
                        :icon="'clean'"
                      ></ButtonSmall>
                    </div>
                    <b-modal
                      :id="`edit-professional_title-modal-${teaching_profile.id}-${matter_form_view}`"
                      :title="`Editar ${$getVisibleNames(
                        'manual.titulo_profesional',
                        false,
                        'Título Profesional'
                      )}`"
                      size="lg"
                      hide-footer
                      ignore-enforce-focus-selector="#sidebar-right"
                    >
                      <NewRichTextEditor
                        :Object="teaching_profile"
                        :Text="teaching_profile.professional_title"
                        @save="updateProfessionalTitle"
                        :permit_blank="true"
                        @close="
                          $bvModal.hide(
                            `edit-professional_title-modal-${teaching_profile.id}-${matter_form_view}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </template>
                </div>
              </b-td>
            </b-tr>
            <b-tr :key="'academic_degree_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.grado_academico",
                      false,
                      "Grado Académico"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <div
                    class="wrapper2 rich-text-content w-100"
                    v-html="teaching_profile.academic_degree"
                  ></div>
                  <template
                    v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  >
                    <div class="d-flex align-content-center p-1">
                      <ButtonSmall
                        tabindex="-1"
                        :tooltip_text="`Editar`"
                        :click_button="
                          () =>
                            $bvModal.show(
                              `edit-academic_degree-modal-${teaching_profile.id}-${matter_form_view}`
                            )
                        "
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>

                      <ButtonSmall
                        v-if="teaching_profile.academic_degree"
                        tabindex="-1"
                        :tooltip_text="`Limpiar`"
                        :click_button="
                          () => clearAcademicDegree(teaching_profile)
                        "
                        :class="`ml-1`"
                        :icon="'clean'"
                      ></ButtonSmall>
                    </div>
                    <b-modal
                      :id="`edit-academic_degree-modal-${teaching_profile.id}-${matter_form_view}`"
                      :title="`Editar ${$getVisibleNames(
                        'manual.grado_academico',
                        false,
                        'Grado Académico'
                      )}`"
                      size="lg"
                      hide-footer
                      ignore-enforce-focus-selector="#sidebar-right"
                    >
                      <NewRichTextEditor
                        :Object="teaching_profile"
                        :Text="teaching_profile.academic_degree"
                        :permit_blank="true"
                        @save="updateAcademicDegree"
                        @close="
                          $bvModal.hide(
                            `edit-academic_degree-modal-${teaching_profile.id}-${matter_form_view}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </template>
                </div>
              </b-td>
            </b-tr>
            <b-tr :key="'specialization_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.especializacion",
                      false,
                      "Especialización"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <div
                    class="wrapper2 rich-text-content w-100"
                    v-html="teaching_profile.specialization"
                  ></div>
                  <template
                    v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  >
                    <div class="d-flex align-content-center p-1">
                      <ButtonSmall
                        tabindex="-1"
                        :tooltip_text="`Editar`"
                        :click_button="
                          () =>
                            $bvModal.show(
                              `edit-specialization-modal-${teaching_profile.id}-${matter_form_view}`
                            )
                        "
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>

                      <ButtonSmall
                        v-if="teaching_profile.specialization"
                        tabindex="-1"
                        :tooltip_text="`Limpiar`"
                        :click_button="
                          () => clearSpecialization(teaching_profile)
                        "
                        :class="`ml-1`"
                        :icon="'clean'"
                      ></ButtonSmall>
                    </div>
                    <b-modal
                      :id="`edit-specialization-modal-${teaching_profile.id}-${matter_form_view}`"
                      :title="`Editar ${$getVisibleNames(
                        'manual.especializacion',
                        false,
                        'Especialización'
                      )}`"
                      size="lg"
                      hide-footer
                      ignore-enforce-focus-selector="#sidebar-right"
                    >
                      <NewRichTextEditor
                        :Object="teaching_profile"
                        :Text="teaching_profile.specialization"
                        @save="updateSpecialization"
                        :permit_blank="true"
                        @close="
                          $bvModal.hide(
                            `edit-specialization-modal-${teaching_profile.id}-${matter_form_view}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </template>
                </div>
              </b-td>
            </b-tr>
            <b-tr :key="'required_skills_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.habilidades_requeridas",
                      true,
                      "Habilidades Requeridas"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <div
                    class="wrapper2 rich-text-content w-100"
                    v-html="teaching_profile.required_skills"
                  ></div>
                  <template
                    v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  >
                    <div class="d-flex align-content-center p-1">
                      <ButtonSmall
                        tabindex="-1"
                        :tooltip_text="`Editar`"
                        :click_button="
                          () =>
                            $bvModal.show(
                              `edit-required_skills-modal-${teaching_profile.id}-${matter_form_view}`
                            )
                        "
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>

                      <ButtonSmall
                        v-if="teaching_profile.required_skills"
                        tabindex="-1"
                        :tooltip_text="`Limpiar`"
                        :click_button="
                          () => clearRequiredSkills(teaching_profile)
                        "
                        :class="`ml-1`"
                        :icon="'clean'"
                      ></ButtonSmall>
                    </div>
                    <b-modal
                      :id="`edit-required_skills-modal-${teaching_profile.id}-${matter_form_view}`"
                      :title="`Editar ${$getVisibleNames(
                        'manual.habilidades_requeridas',
                        true,
                        'Habilidades Requeridas'
                      )}`"
                      size="lg"
                      hide-footer
                      ignore-enforce-focus-selector="#sidebar-right"
                    >
                      <NewRichTextEditor
                        :Object="teaching_profile"
                        :Text="teaching_profile.required_skills"
                        @save="updateRequiredSkills"
                        :permit_blank="true"
                        @close="
                          $bvModal.hide(
                            `edit-required_skills-modal-${teaching_profile.id}-${matter_form_view}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </template>
                </div>
              </b-td>
            </b-tr>
            <b-tr :key="'work_experience_validity' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.vigencia_experiencia_laboral",
                      false,
                      "Vigencia Experiencia Laboral"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div class="d-flex">
                  <div
                    class="wrapper2 rich-text-content w-100"
                    v-html="teaching_profile.work_experience_validity"
                  ></div>
                  <template
                    v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  >
                    <div class="d-flex align-content-center p-1">
                      <ButtonSmall
                        tabindex="-1"
                        :tooltip_text="`Editar`"
                        :click_button="
                          () =>
                            $bvModal.show(
                              `edit-work_experience_validity-modal-${teaching_profile.id}-${matter_form_view}`
                            )
                        "
                        :class="``"
                        :icon="'square'"
                      ></ButtonSmall>

                      <ButtonSmall
                        v-if="teaching_profile.work_experience_validity"
                        tabindex="-1"
                        :tooltip_text="`Limpiar`"
                        :click_button="
                          () => clearWorkExperienceValidity(teaching_profile)
                        "
                        :class="`ml-1`"
                        :icon="'clean'"
                      ></ButtonSmall>
                    </div>
                    <b-modal
                      :id="`edit-work_experience_validity-modal-${teaching_profile.id}-${matter_form_view}`"
                      :title="`${$getVisibleNames(
                        'manual.vigencia_experiencia_laboral',
                        false,
                        'Vigencia Experiencia Laboral'
                      )}`"
                      size="lg"
                      hide-footer
                      ignore-enforce-focus-selector="#sidebar-right"
                    >
                      <NewRichTextEditor
                        :Object="teaching_profile"
                        :Text="teaching_profile.work_experience_validity"
                        @save="updateWorkExperienceValidity"
                        :permit_blank="true"
                        @close="
                          $bvModal.hide(
                            `edit-work_experience_validity-modal-${teaching_profile.id}-${matter_form_view}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </template>
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else-if="allows_crud">
          <template>
            <b-tr>
              <b-th colspan="10" rowspan="1">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="flex-grow-1 text-center"> PERFIL DOCENTE </span>
                  <div>
                    <ButtonSmall
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                      :tooltip_text="`Agregar un nuevo Perfil Docente`"
                      :click_button="createTeachingProfileBase"
                      :class="`mr-1`"
                      :icon="'plus'"
                      :variant="'none'"
                    >
                    </ButtonSmall>
                  </div>
                </div>                
              </b-th>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "TeachingProfileComponent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    matter_form_view: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    matter: {
      type: [Object, null],
    },
  },
  data() {
    return {
      fetch_finish: false,
      teaching_profiles: [],
    };
  },
  computed: {
    ...mapGetters({
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
    matterHours() {
      let list = [];
      this.time_allocations.forEach((element) => {
        if (this.matter && !this.matter.use_matter_values) {
          const matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == this.matter.id &&
              x.time_allocation == element.id
          );
          if (matter_hour)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              egress_profile_matter: matter_hour.egress_profile_matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.matter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.matter.weeks)
                    )
                  : 0,
            });
        } else {
          const matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == this.oldMatter.id && x.time_allocation == element.id
          );
          if (matter_hour && this.oldMatter)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              matter: matter_hour.matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.oldMatter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.oldMatter.weeks)
                    )
                  : 0,
            });
        }
      });
      return list;
    },
    totalPedagogical() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
  },
  methods: {
    fetchTeachingProfile() {
      if (this.oldMatter) {
        this.$restful
          .Get(`/teaching/teaching-profile/?matter=${this.oldMatter.id}`)
          .then((response) => {
            this.teaching_profiles = response;
            this.fetch_finish = true;
            // if (response.length == 0 && this.allows_crud)
            //   this.createTeachingProfileBase();
          });
      }
    },
    createTeachingProfileBase() {
      this.createTeachingProfile({
        professional_title: "",
        academic_degree: "",
        specialization: "",
        required_skills: "",
        total_hours: 0,
        experience_years: 0,
        work_experience_validity: "",
      });
    },
    deleteTeachingProfile(teaching_profile) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Perfil Docente?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/teaching-profile/${teaching_profile.id}/`)
            .then(() => {
              const index = this.teaching_profiles.findIndex(
                (x) => x.id == teaching_profile.id
              );
              if (index != -1) {
                this.$emit("change_teaching_profile");
                this.teaching_profiles.splice(index, 1);
                toast("Se eliminó el Perfil Docente.");
              }
            });
        }
      });
    },
    maxHoursTeachingProfile(teaching_profile_id) {
      return this.teaching_profiles
        .filter((x) => x.id != teaching_profile_id)
        .map((x) => x.total_hours)
        .reduce((a, b) => a + b, 0);
    },
    patchHourTeachingProfile(teaching_profile) {
      if (
        teaching_profile.total_hours >= 0 &&
        teaching_profile.experience_years >= 0 &&
        this.totalPedagogical -
          this.maxHoursTeachingProfile(teaching_profile.id) >=
          teaching_profile.total_hours
      )
        this.patchTeachingProfile(teaching_profile);
      else if (teaching_profile.total_hours && teaching_profile.total_hours < 0)
        teaching_profile.total_hours = 0;
    },
    patchTeachingProfile(teaching_profile) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.saveTeachingProfile(teaching_profile);
      }, 1000);
    },
    clearProfessionalTitle(teaching_profile) {
      teaching_profile.professional_title = "";
      this.updateTeachingProfile(teaching_profile);
    },
    clearAcademicDegree(teaching_profile) {
      teaching_profile.academic_degree = "";
      this.updateTeachingProfile(teaching_profile);
    },
    clearSpecialization(teaching_profile) {
      teaching_profile.specialization = "";
      this.updateTeachingProfile(teaching_profile);
    },
    clearRequiredSkills(teaching_profile) {
      teaching_profile.required_skills = "";
      this.updateTeachingProfile(teaching_profile);
    },
    clearWorkExperienceValidity(teaching_profile) {
      teaching_profile.work_experience_validity = "";
      this.updateTeachingProfile(teaching_profile);
    },
    updateProfessionalTitle(teaching_profile, text) {
      teaching_profile.professional_title = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateAcademicDegree(teaching_profile, text) {
      teaching_profile.academic_degree = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateSpecialization(teaching_profile, text) {
      teaching_profile.specialization = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateRequiredSkills(teaching_profile, text) {
      teaching_profile.required_skills = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateWorkExperienceValidity(teaching_profile, text) {
      teaching_profile.work_experience_validity = text;
      this.saveTeachingProfile(teaching_profile);
    },
    saveTeachingProfile(teaching_profile) {
      if (teaching_profile.id == -1)
        this.createTeachingProfile(teaching_profile);
      else this.updateTeachingProfile(teaching_profile);
    },
    createTeachingProfile(teaching_profile) {
      if (this.oldMatter.id) {
        teaching_profile.matter = this.oldMatter.id;
        this.$restful
          .Post(`/teaching/teaching-profile/`, teaching_profile)
          .then((response) => {
            this.teaching_profiles.push(response);
            this.$emit("change_teaching_profile");
            toast("Perfil Docente creado.");
          });
      }
    },
    mutateTeachingProfile(response) {
      const index = this.teaching_profiles.findIndex(
        (x) => x.id == response.id
      );
      if (index != -1) {
        this.teaching_profiles[index].professional_title =
          response.professional_title;
        this.teaching_profiles[index].academic_degree =
          response.academic_degree;
        this.teaching_profiles[index].specialization = response.specialization;
        this.teaching_profiles[index].required_skills =
          response.required_skills;
        this.teaching_profiles[index].total_hours = response.total_hours;
        this.teaching_profiles[index].experience_years =
          response.experience_years;
        this.teaching_profiles[index].work_experience_validity =
          response.work_experience_validity;
      }
    },
    updateTeachingProfile(teaching_profile) {
      this.$restful
        .Put(
          `/teaching/teaching-profile/${teaching_profile.id}/`,
          teaching_profile
        )
        .then((response) => {
          if (response) {
            this.$emit("change_teaching_profile");
            this.mutateTeachingProfile(response);
            toast("Perfil Docente actualizado.");
          }
        });
    },
  },
  created() {
    this.fetchTeachingProfile();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 0px 0px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
table td input[type="text"] {
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  min-height: 100%;
}
.wrapper2 {
  text-align: justify;
  margin: 10px;
}
.badge-teaching {
  align-items: center !important;
  white-space: nowrap;
}
.input-number-hour {
  max-width: 90px;
  height: 23px;
}
.input-number-year {
  max-width: 60px;
  height: 23px;
}
</style>