var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-tbody',[(_vm.teaching_profiles.length > 0)?[_vm._l((_vm.teaching_profiles),function(teaching_profile,index){return [_c('b-tr',{key:'profile_header_' + teaching_profile.id},[_c('b-th',{attrs:{"colspan":"10","rowspan":"1"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"flex-grow-1 text-center"},[_vm._v(" PERFIL DOCENTE "+_vm._s(index + 1)+" ")]),_c('div',[(
                      index == 0 &&
                      _vm.allows_crud &&
                      _vm.oldMatter &&
                      !_vm.oldMatter.is_closed
                    )?_c('ButtonSmall',{class:`ml-1`,attrs:{"tooltip_text":`Agregar un nuevo Perfil Docente`,"click_button":_vm.createTeachingProfileBase,"icon":'plus',"variant":'none'}}):_vm._e(),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('ButtonSmall',{class:`ml-1`,attrs:{"click_button":() => _vm.deleteTeachingProfile(teaching_profile),"tooltip_text":`Eliminar el Perfil Docente`,"icon":'trash'}}):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"mr-2 secondary-color rounded badge-teaching d-flex",staticStyle:{"color":"white !important","padding":"0.1em"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" "),(!_vm.allows_crud || (_vm.oldMatter && _vm.oldMatter.is_closed))?_c('span',[_vm._v(": "+_vm._s(teaching_profile.total_hours ? teaching_profile.total_hours : "N/A")+" ")]):_c('b-form-input',{staticClass:"input-number-hour ml-1",attrs:{"id":`input-teaching-profile-${teaching_profile.id}`,"type":"number","min":"0","state":_vm.totalPedagogical -
                        _vm.maxHoursTeachingProfile(teaching_profile.id) >=
                      teaching_profile.total_hours},on:{"input":function($event){return _vm.patchHourTeachingProfile(teaching_profile)}},model:{value:(teaching_profile.total_hours),callback:function ($$v) {_vm.$set(teaching_profile, "total_hours", _vm._n($$v))},expression:"teaching_profile.total_hours"}}),(
                      _vm.allows_crud &&
                      _vm.totalPedagogical -
                        _vm.maxHoursTeachingProfile(teaching_profile.id) <
                        teaching_profile.total_hours
                    )?_c('b-popover',{attrs:{"variant":"warning","placement":"bottom","target":`input-teaching-profile-${teaching_profile.id}`,"show":""}},[[_vm._v(" La suma de las horas de los docentes superó el máximo: "+_vm._s(_vm.totalPedagogical)+" ")]],2):_vm._e()],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.top",value:(
                    `Años de experiencia laboral en el área.`
                  ),expression:"\n                    `Años de experiencia laboral en el área.`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"top":true}}],staticClass:"mr-2 secondary-color rounded badge-teaching d-flex",staticStyle:{"color":"white !important","padding":"0.1em"}},[_vm._v(" Años de experiencia profesional "),(!_vm.allows_crud || (_vm.oldMatter && _vm.oldMatter.is_closed))?_c('span',[_vm._v(": "+_vm._s(teaching_profile.experience_years ? teaching_profile.experience_years : "N/A")+" ")]):_c('b-form-input',{staticClass:"input-number-year ml-1",attrs:{"type":"number","min":"0"},on:{"input":(value) => {
                        if (value >= 0 && teaching_profile.total_hours >= 0)
                          _vm.patchTeachingProfile(teaching_profile);
                        else if (value < 0)
                          teaching_profile.experience_years = 0;
                      }},model:{value:(teaching_profile.experience_years),callback:function ($$v) {_vm.$set(teaching_profile, "experience_years", _vm._n($$v))},expression:"teaching_profile.experience_years"}})],1)])])],1),_c('b-tr',{key:'professional_title_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.titulo_profesional", false, "Título Profesional" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.professional_title)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                          _vm.$bvModal.show(
                            `edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                          ),"icon":'square'}}),(teaching_profile.professional_title)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearProfessionalTitle(teaching_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                      'manual.titulo_profesional',
                      false,
                      'Título Profesional'
                    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.professional_title,"permit_blank":true},on:{"save":_vm.updateProfessionalTitle,"close":function($event){return _vm.$bvModal.hide(
                          `edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'academic_degree_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.grado_academico", false, "Grado Académico" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.academic_degree)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                          _vm.$bvModal.show(
                            `edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                          ),"icon":'square'}}),(teaching_profile.academic_degree)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearAcademicDegree(teaching_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                      'manual.grado_academico',
                      false,
                      'Grado Académico'
                    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.academic_degree,"permit_blank":true},on:{"save":_vm.updateAcademicDegree,"close":function($event){return _vm.$bvModal.hide(
                          `edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'specialization_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.especializacion", false, "Especialización" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.specialization)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                          _vm.$bvModal.show(
                            `edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                          ),"icon":'square'}}),(teaching_profile.specialization)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearSpecialization(teaching_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                      'manual.especializacion',
                      false,
                      'Especialización'
                    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.specialization,"permit_blank":true},on:{"save":_vm.updateSpecialization,"close":function($event){return _vm.$bvModal.hide(
                          `edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'required_skills_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.habilidades_requeridas", true, "Habilidades Requeridas" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.required_skills)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                          _vm.$bvModal.show(
                            `edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                          ),"icon":'square'}}),(teaching_profile.required_skills)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearRequiredSkills(teaching_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                      'manual.habilidades_requeridas',
                      true,
                      'Habilidades Requeridas'
                    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.required_skills,"permit_blank":true},on:{"save":_vm.updateRequiredSkills,"close":function($event){return _vm.$bvModal.hide(
                          `edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'work_experience_validity' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.vigencia_experiencia_laboral", false, "Vigencia Experiencia Laboral" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.work_experience_validity)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('div',{staticClass:"d-flex align-content-center p-1"},[_c('ButtonSmall',{class:``,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                          _vm.$bvModal.show(
                            `edit-work_experience_validity-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                          ),"icon":'square'}}),(teaching_profile.work_experience_validity)?_c('ButtonSmall',{class:`ml-1`,attrs:{"tabindex":"-1","tooltip_text":`Limpiar`,"click_button":() => _vm.clearWorkExperienceValidity(teaching_profile),"icon":'clean'}}):_vm._e()],1),_c('b-modal',{attrs:{"id":`edit-work_experience_validity-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`${_vm.$getVisibleNames(
                      'manual.vigencia_experiencia_laboral',
                      false,
                      'Vigencia Experiencia Laboral'
                    )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.work_experience_validity,"permit_blank":true},on:{"save":_vm.updateWorkExperienceValidity,"close":function($event){return _vm.$bvModal.hide(
                          `edit-work_experience_validity-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()],2)])],1)]})]:(_vm.allows_crud)?[[_c('b-tr',[_c('b-th',{attrs:{"colspan":"10","rowspan":"1"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"flex-grow-1 text-center"},[_vm._v(" PERFIL DOCENTE ")]),_c('div',[(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('ButtonSmall',{class:`mr-1`,attrs:{"tooltip_text":`Agregar un nuevo Perfil Docente`,"click_button":_vm.createTeachingProfileBase,"icon":'plus',"variant":'none'}}):_vm._e()],1)])])],1)]]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }